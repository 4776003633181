import React from "react"

import styles from "./videoInline.module.scss"

const Video = props => {
  const videoAttrs = {
    className: styles.video,
    autoPlay: true,
    loop: true,
    playsInline: true,
  }
  // Set up <source> attributes
  const sourceAttrs = {
    key: props.src,
    src: props.src,
    type: props.type,
    poster: props.poster,
  }

  return (
    <video muted={true} {...videoAttrs}>
      <source {...sourceAttrs} />
    </video>
  )
}

export default Video
