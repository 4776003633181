import React from "react"

import styles from "./preFooter.module.scss"

const PreFooter = () => {
  return (
    <div className={`${styles.prefooter}`}>
      <div className="wrapper">
        <p className="a-h-6">
          Athena is a playground for uncommon wisdom. Created by Zeus Jones.
        </p>
      </div>
    </div>
  )
}

export default PreFooter
