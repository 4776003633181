import React from "react"

import styles from "./scrollProgressBar.module.scss"
import useViewportState from "../hooks/useViewportState"

export default function ScrollProgressBar() {
  let ref = React.useRef()
  useViewportState(
    scaleX => {
      let node = ref.current
      if (node != null) {
        node.style.transform = `scaleX(${scaleX})`
      }
    },
    state => state.scrollTop / state.maxScroll
  )
  // Un-scoped class is for other components to change the background color
  return <div ref={ref} className={`${styles.container} scroll-progress-bar`} />
}
