import React from "react"
import { Link, graphql } from "gatsby"
import widont from "widont"

import styles from "./athenaCta.module.scss"

const AthenaCta = props => {
  // Return if it's set to hidden
  if (props.data[0].hide) {
    return null
  }
  const body = props.data[0].body
  const linkText = props.data[0].linkText
  const linkUri = props.data[0].linkPage[0].uri

  return (
    <div className={`${styles.cta} athena-cta`}>
      <div className="wrapper">
        <div className="columns is-centered">
          <div className="column is-10-tablet is-8-widescreen">
            <div className={styles.ctaContent}>
              <div
                className="mb-2 mono"
                dangerouslySetInnerHTML={{
                  __html: widont(body),
                }}
              />
              <Link className="link" to={`/${linkUri}`}>
                {linkText}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment AthenaCta on Craft_athenaCta_BlockType {
    body
    linkText
    linkPage {
      ... on Craft_EntryInterface {
        title
        uri
        id
      }
    }
    hide
  }
`

export default AthenaCta
