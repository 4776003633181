import React from "react"
import { graphql } from "gatsby"

import ArticleHeader from "../../../components/athena/articleHeader.js"
import Contributors from "../../../components/athena/contributors.js"
import AthenaCta from "../../../components/athena/athenaCta.js"
import Layout, { zeusTheme } from "../../../components/layout"
import MoreArticles from "../../../components/athena/moreArticles.js"
import PreFooter from "../../../components/athena/preFooter"
import ScrollProgressBar from "../../../components/scrollProgressBar"
import { useIsTouch } from "../../../hooks"
import styles from "./defining-audiences.module.scss"

let theme = {
  ...zeusTheme,
  bodyClass: "athena",
}

export default ({ data }) => {
  const article = data.craft.article
  const seo = article.seomatic
  const coverImages = article.coverImages ? article.coverImages[0] : null
  const athenaCta = article.athenaCta

  let isTouch = useIsTouch()

  let articleRef = React.useRef()

  React.useEffect(() => {
    let articleNode = articleRef.current
    if (articleNode == null) return

    let videos = [].slice.call(articleNode.querySelectorAll("." + styles.video))

    if (isTouch) {
      videos.forEach(v => v.play())
      return
    }

    let observer = new IntersectionObserver(
      function callback(entries, observer) {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.play()
            entry.target.classList.remove(styles.videoPaused)
          } else {
            entry.target.pause()
            entry.target.classList.add(styles.videoPaused)
          }
        })
      },
      {
        rootMargin: "-50% 0px -50% 0px",
      }
    )

    videos.forEach(video => {
      video.pause()
      observer.observe(video)
    })

    return () => {
      observer.disconnect()
    }
  }, [articleRef, isTouch])

  return (
    <Layout seo={seo} theme={theme} mode="athena">
      <article>
        <ScrollProgressBar />
        <div className={styles.article}>
          <ArticleHeader
            issue={article.issue}
            title={article.title}
            subtitle={article.articleSubTitle}
            coverImages={coverImages}
          />
          <div className={styles.articleBody} ref={articleRef}>
            <div className="wrapper">
              <div className={styles.largeQuoteSection}>
                <div className="columns text-center user-content-athena">
                  <div className="column is-8 is-offset-2">
                    <p className="a-h-1">
                      People are <i>getting more fluid</i>. And the way we
                      measure them seems more rigid than ever.
                    </p>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column is-5">
                  <Video src="https://player.vimeo.com/external/378647595.hd.mp4?s=a5d8c8f4ac05919915f2c8338d67096133b44bc5&profile_id=175&muted=1" />
                </div>
                <div className="column is-offset-1 user-content-athena">
                  <p>Marketers build realities to reach people.</p>
                  <p>
                    But the frameworks we use to define and reach identities and
                    audiences are a relic of an older way of seeing the world.
                  </p>
                  <p>
                    We’re trained to group people into massive segments based on
                    demographic data: race, gender, age, education, income. Or
                    we might identify and name a consumer archetype we’re trying
                    to reach.{" "}
                  </p>
                  <p>
                    We talk about made-up “buckets” or “users” or “shoppers,”
                    not people.
                  </p>
                  <blockquote className={styles.quote}>
                    <p>
                      Marketers build realities to <i>reach people</i>.
                    </p>
                  </blockquote>
                </div>
              </div>
              <div className="columns">
                <div className="column is-5">
                  <Video src="https://player.vimeo.com/external/378647922.hd.mp4?s=dfa60e3866bab0fafb1ea3fe5eb9d5d9a79e3e5e&profile_id=175&muted=1" />
                </div>
                <div className="column is-offset-1 user-content-athena">
                  <p>
                    This framework is built on trait theory, which says that
                    human behavior is driven by mostly permanent aspects of our
                    identity.
                  </p>
                  <p>
                    The choices we make, it says, are driven by our physical,
                    demographic or emotional traits. In the long run, our
                    choices will stay generally consistent within the lanes of
                    these labels.
                  </p>
                  <p>
                    This theory makes it way easier for marketers to understand
                    and reach people. If you live like this, you’ll probably buy
                    that. Simply pick a lane and tightly target it, and you’ll
                    see results.
                  </p>
                </div>
              </div>
              <div className="columns">
                <div className="column is-5">
                  <Video src="https://player.vimeo.com/external/378648152.hd.mp4?s=3ff6d0d31dd695a509dcf700de860e41f8dc858a&profile_id=175&muted=1" />
                </div>
                <div className="column is-offset-1 user-content-athena">
                  <p>
                    But our traits aren’t the only things influencing our
                    actions. You’re not only driven by your emotional state, but
                    your context.
                  </p>
                  <p>
                    This is called <i>situationism</i>.
                  </p>
                  <p>
                    When we’re driven by emotions like desire or stress, our
                    personalities can be overruled. The interruptions of the
                    world drive what we do — and how we buy.
                  </p>
                  <p>
                    Marketers define the emotional benefits of their brands,
                    then inject that state of mind into a person’s
                    decision-making.
                  </p>
                </div>
              </div>
              <div className="columns user-content-athena">
                <div className="column is-9-tablet is-offset-1-tablet">
                  <blockquote className={styles.quote}>
                    <p>
                      When we’re <i>driven by emotions</i> like desire or
                      stress, our personalities can be overruled. The
                      interruptions of the world drive what we do — and{" "}
                      <i>how we buy</i>.
                    </p>
                  </blockquote>
                </div>
              </div>
              <div className="columns">
                <div className="column is-5">
                  <Video src="https://player.vimeo.com/external/378648305.hd.mp4?s=4a543b21cfee1d17a259f50a65239a2337639926&profile_id=175&muted=1" />
                </div>
                <div className="column is-offset-1 user-content-athena">
                  <p>
                    But we believe the modern nature of identity is defined
                    somewhere in the middle.
                  </p>
                  <p>
                    Most people build their place in the world through an
                    interactionist mindset. We take our traits, our experiences,
                    our choices — and, crucially, our interactions with others —
                    to negotiate our identity.
                  </p>
                  <p>And it’s rarely as permanent as marketers believe.</p>
                  <blockquote className={styles.quote}>
                    <p>
                      Identity isn't a <i>one-word label</i> anymore. It’s a
                      work-in-progress.
                    </p>
                  </blockquote>
                  <p>
                    Today, we’re much less likely to adhere to traditional roles
                    and expectations defined by our demographic details.
                  </p>
                  <p>
                    Identity isn’t a one-word label anymore. It’s a
                    work-in-progress. We celebrate, blend and redefine the
                    components that make us us. We’re not part-this, part-that —
                    we’re this-and.
                  </p>
                  <p>
                    And what rises to the surface is always in a state of
                    change.
                  </p>
                  <p>
                    Oxford philosopher Nick Bostrom describes identity as a
                    group of layers, which are built and rearranged by our
                    interactions.
                  </p>
                  <p>Our core self is our experiences and lived history.</p>
                  <p>
                    Our narrative self is how we interpret the past, present and
                    future attributes of our lives.
                  </p>
                  <p>
                    Our social identity is built by the personas and roles we
                    adopt. Most people have a rich structure of social
                    identities.
                  </p>
                  <p>
                    A fascinating new addition is our digital identity, which
                    includes our biometrics, our data, our legal statuses and
                    our online personas. And the relationship between our
                    digital and IRL identities may be totally incongruent.
                  </p>
                </div>
              </div>
              <div className="columns user-content-athena">
                <div className="column is-9 is-offset-1">
                  <blockquote className={styles.quote}>
                    <p>
                      Oxford philosopher Nick Bostrom describes identity as{" "}
                      <i>a group of layers</i>, which{" "}
                      <i>are built and rearranged</i> by our interactions.
                    </p>
                  </blockquote>
                </div>
              </div>
              <div className="columns">
                <div className="column is-5">
                  <Video src="https://player.vimeo.com/external/378648488.hd.mp4?s=c278c9e7da6193963c58d58adb55ea62d7953f82&profile_id=175&muted=1" />
                </div>
                <div className="column is-offset-1 user-content-athena">
                  <p>
                    We can (and do) choose to shift our identities, but they’re
                    also sensitive to our daily lives.
                  </p>
                  <p>
                    We’re social creatures, and our identities are attuned to
                    other people. When we interact, an exchange of energy takes
                    place — and the attributes of our personality evolve.
                  </p>
                  <p>
                    Some people inspire us to learn, understand and empathize.
                    Others we may judge, critique or avoid. As we live more of
                    these interactions, our identities get more complex.
                  </p>
                  <p>
                    Our ever-increasing access to information and interactions
                    helps us create these connections faster and more
                    meaningfully.
                  </p>
                  <p>
                    And that only accelerates the process of unlearning a
                    traditional identity. We increasingly adopt and incorporate
                    elements of culture from around the world. A global identity
                    of individuals is taking form, formed by the common
                    languages of food, fashion, music and culture.
                  </p>
                  <p>
                    If the way identity is being formed is rapidly evolving —
                    and the way we understand one another is as well — why
                    haven’t businesses caught up in order to better serve us?
                  </p>
                </div>
              </div>
              <div className="columns">
                <div className="column is-5">
                  <Video src="https://player.vimeo.com/external/378648734.hd.mp4?s=f93c827041653a76ffcef7638163d25951ef46c5&profile_id=175&muted=1" />
                </div>
                <div className="column is-offset-1 user-content-athena">
                  <p>
                    All these changes mean the traditional business definitions
                    of audiences and segments have largely become less
                    effective. Today’s best brands understand that their
                    audiences are defined in a nimble, fluid space — not by
                    inflexible, established identities.
                  </p>
                  <p>
                    Now, people gravitate toward expressions, convictions and
                    experiences that resonate with them — both from other people
                    and from the brands they buy.
                  </p>
                  <p>
                    So, the best brands define the convictions that motivate
                    them. They build ways of seeing the world that express
                    something fundamental about their brands — and then attract
                    people who share that vision. The audience becomes a
                    personal expression, not a type of person.
                  </p>
                  <p>
                    And marketers ask questions about identity, culture symbols
                    and experience that connect people to inform business
                    opportunity. The result is a more compelling, human and
                    relatable universe of brand identity.
                  </p>
                </div>
              </div>
            </div>
            <Contributors
              zeus={article.zeusContributors}
              other={article.otherContributors}
            />
            <AthenaCta data={athenaCta} />
            <MoreArticles id={article.id} issue={article.issue[0].id} />
          </div>
          {/* .article-body */}
        </div>
        {/* .article */}
      </article>
      <PreFooter />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    craft {
      article: entry(slug: [$slug], section: "athenaArticles") {
        title
        id
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_athenaArticles_athenaArticleCustom_Entry {
          articleSubTitle
          issue {
            id
            title
          }
          coverImages {
            ... on Craft_coverImages_BlockType {
              ratio_16x9 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
              ratio_4x3 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
            }
          }
          zeusContributors {
            id
            title
            uri
          }
          otherContributors {
            ... on Craft_otherContributors_TableRow {
              name
              website
            }
          }
          athenaCta {
            ...AthenaCta
          }
        }
      }
    }
  }
`

function Video({ src }) {
  return (
    <div
      className={styles.sticker}
      style={{
        height: 0,
        paddingBottom: "100%",
      }}
    >
      <video playsInline muted loop className={styles.video}>
        <source src={src} type="video/mp4" />
      </video>
    </div>
  )
}
